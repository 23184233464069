import React from 'react'

import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import { useQueryClient } from 'react-query'

import useNextRouting from 'app/hooks/useNextRouting'
import { vehicleKeys } from 'app/services/Query/vehicle/vehicle.keys'
import useAppStore from 'app/store/app.store'
import useAuthStore from 'app/store/auth.store'
import {
  ELASTIC_SEARCH_API,
  ELASTIC_SEARCH_KEY,
  IS_NATIVE_APP
} from 'app/utils/constants/env.constants'
import { USER_ROLE } from 'app/utils/constants/role.constants'
import {
  getElasticEngineName,
  getFormattedSearchFilters,
  getVehicleUniversalFilters
} from 'app/utils/helpers/elastic.helpers'
import { getSearchCategoryId } from 'app/utils/helpers/listings.helpers'

import ElasticSearchProvider from './ElasticSearchProvider'

export type ElasticSearchProviderProps = React.ComponentProps<
  typeof ElasticSearchProvider
>

const facetCityLimit = 250
const facetCountryLimit = 50

const connector = new AppSearchAPIConnector({
  searchKey: ELASTIC_SEARCH_KEY,
  engineName: getElasticEngineName('listings'),
  endpointBase: ELASTIC_SEARCH_API,
  ...{ cacheResponses: false }
})

export const FACETS = {
  part_number: { type: 'value' },
  country: { type: 'value', size: facetCountryLimit },
  full_country_name: { type: 'value', size: facetCountryLimit },
  city: { type: 'value', size: facetCityLimit }
}

export const DISJUNCTIVE_FACETS = [
  'part_number',
  'country',
  'full_country_name',
  'city'
]

export const SEARCH_FIELDS = {
  title: {},
  makes: {},
  models: {},
  part_number: {},
  description: {},
  series_ids: {},
  trims_ids: {}
}

export const RESULT_FIELDS = {
  makes: { raw: {} },
  title: { raw: {} },
  id: { raw: {} },
  city: { raw: {} },
  part_number: { raw: {} },
  usd_price: { raw: {} },
  eur_price: { raw: {} },
  aud_price: { raw: {} },
  gbp_price: { raw: {} },
  jpy_price: { raw: {} },
  attachment_url: { raw: {} },
  slug: { raw: {} },
  seller_id: { raw: {} },
  auction_close_date: { raw: {} },
  auction_reserve_status: { raw: {} },
  status: { raw: {} },
  international_shipping: { raw: {} },
  usd_trade_price: { raw: {} },
  eur_trade_price: { raw: {} },
  aud_trade_price: { raw: {} },
  gbp_trade_price: { raw: {} },
  jpy_trade_price: { raw: {} },
  trade_pricing: { raw: {} },
  subtitle: { raw: {} }
}

type SearchProviderProps = Omit<ElasticSearchProviderProps, 'config'> & {
  initialState?: ElasticSearchProviderProps['config']['initialState']
  searchQuery?: ElasticSearchProviderProps['config']['searchQuery']
  searchCategory?: string
}

const SearchProvider = ({
  initialState,
  searchQuery,
  searchCategory,
  children
}: SearchProviderProps) => {
  const queryClient = useQueryClient()

  const configurationOptions: ElasticSearchProviderProps['config'] = {
    apiConnector: connector,
    initialState: {
      resultsPerPage: 20,
      ...initialState
    },
    onSearch: (state, config, next) => {
      const { filters = [], sortField, ...restState } = state || {}
      const { currency } = useAppStore.getState()
      const newFilters = [...filters]

      const { user } = useAuthStore.getState()
      const isBusinessUser = user?.role === USER_ROLE.BUSINESS

      // update price after change currency
      const priceIndex = filters?.findIndex(({ field }) => field === 'price')
      const isPriceSort = sortField === 'price'
      const fieldPrice = isBusinessUser
        ? `${currency}_trade_price`
        : `${currency}_price`

      if (priceIndex !== -1) {
        const priceFilter = newFilters[priceIndex]

        newFilters.splice(priceIndex, 1, {
          type: priceFilter?.type || 'any',
          values: priceFilter?.values || [],
          field: fieldPrice
        })
      }

      // setup category if category page
      const categoryId = getSearchCategoryId({ searchCategory, queryClient })

      if (!!categoryId) {
        newFilters.push({
          type: 'any',
          values: [categoryId],
          field: 'category_id'
        })
      }

      const universalParams =
        queryClient.getQueryData<VehicleUniversalParamsModel>(
          vehicleKeys.getVehicleUniversalParams()
        )

      // get vehicle filters with universal option searching
      const vehicleFilters = getVehicleUniversalFilters(
        newFilters,
        universalParams
      )

      const isVehicleFilters = !!vehicleFilters && vehicleFilters?.length > 0
      const formattedFilters = isVehicleFilters
        ? getFormattedSearchFilters(newFilters, vehicleFilters)
        : newFilters

      const newState = {
        ...restState,
        sortField: isPriceSort ? fieldPrice : sortField,
        filters: formattedFilters
      }

      return next(newState, config)
    },
    searchQuery,
    trackUrlState: IS_NATIVE_APP ? false : true,
    alwaysSearchOnInitialLoad: true
  }

  const config = useNextRouting(configurationOptions, 'search')

  return (
    <ElasticSearchProvider config={config}>{children}</ElasticSearchProvider>
  )
}

export default SearchProvider
