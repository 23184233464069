import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { ElasticSearchProviderProps } from 'app/features/SearchFeature/providers/SearchProvider'
import {
  parseQueryParams,
  stringifyQueryParams
} from 'app/services/QueryParams/utils'
import { IS_BROWSER } from 'app/utils/constants/env.constants'
import {
  SearchParams,
  transformQueryParamsToSearchParams,
  transformSearchParamsToQueryParams
} from 'app/utils/helpers/elastic.helpers'
import { isOfType } from 'app/utils/helpers/type.helpers'
import { SearchSchema } from 'app/validation/schemas/search.schemas'
import { getValidValuesBySchema } from 'app/validation/validation.utils'

const useNextRouting = (config, basePathUrl) => {
  const router = useRouter()
  const { asPath, query } = router
  const category = isOfType.string(query?.category) ? query?.category : null

  const routingOptions: ElasticSearchProviderProps['config']['routingOptions'] =
    {
      // read and write only the query string to search UI
      // as we are leveraging existing stateToUrl and urlToState functions
      // which are based on the query string
      readUrl: () => {
        return asPath
      },
      stateToUrl: (state) => {
        const params = transformSearchParamsToQueryParams(state as SearchParams)

        const search = stringifyQueryParams(params)

        const basePath = !!category ? `/${category}` : '/'

        return search ? `${basePath}?${search}` : basePath
      },
      urlToState: (url) => {
        const basePath = url.split('?')[0] ?? '/'

        const parseParams = parseQueryParams(url.replace(basePath, ''))

        const validParams = getValidValuesBySchema(SearchSchema, parseParams)

        const state = transformQueryParamsToSearchParams(validParams)

        if (!Object.keys(parseParams || {}).length) return {}

        return state
      },
      writeUrl: (url, { replaceUrl }) => {
        // We can use useRouter/nextjs here but have double rendering issues
        const method = router[replaceUrl ? 'replace' : 'push']

        if (url === '/') return

        method(url, undefined, {
          shallow: true
        })
      },
      routeChangeHandler: (callback) => {
        const handler = (fullUrl: string) => {
          if (basePathUrl === 'search') {
            callback(fullUrl.replace('/', ''))
          }
        }

        const handleChangeRouter = () => {
          handler(window?.location?.search)
        }

        if (IS_BROWSER) {
          window?.addEventListener('popstate', handleChangeRouter)
        }

        return () => {
          if (IS_BROWSER)
            window.removeEventListener('popstate', handleChangeRouter)
        }
      }
    }

  return useMemo(() => {
    return {
      ...config,
      routingOptions
    }
  }, [router.isReady])
}

export default useNextRouting
