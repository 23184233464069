import { QueryClient } from 'react-query'

import { listingKeys } from 'app/services/Query/listing/listing.keys'
import {
  INVENTORY_QUERY_STATUS,
  LISTING_QUERY_STATUS,
  LISTING_STATUS
} from 'app/utils/constants/status.constants'

interface GetStatusForParamsType {
  queryStatus: string
  formStatus?: string
  isBusiness?: boolean
  isHolidayMode?: boolean
}

export const getListingsStatus = ({
  queryStatus,
  formStatus,
  isBusiness,
  isHolidayMode
}: GetStatusForParamsType) => {
  const isAvailable = queryStatus === LISTING_STATUS.available

  if (isBusiness) {
    const availableStatus = isHolidayMode
      ? INVENTORY_QUERY_STATUS.seller_on_holiday_mode
      : INVENTORY_QUERY_STATUS.available_and_sold_out

    const inventoryStatus = isAvailable
      ? availableStatus
      : INVENTORY_QUERY_STATUS.draft

    const listingsStatus = formStatus
      ? INVENTORY_QUERY_STATUS[formStatus]
      : inventoryStatus

    return listingsStatus
  } else {
    const listingsStatus =
      isHolidayMode && isAvailable
        ? LISTING_QUERY_STATUS.seller_on_holiday_mode
        : LISTING_QUERY_STATUS[queryStatus]

    return listingsStatus || LISTING_QUERY_STATUS.available
  }
}

export const getSubcategoryHeader = (option: SubCategory): string => {
  const isSubcategoryHeaders = option.parent?.subcategory_headers
  const subcategoryHeader = option?.header || 'Other'
  const header = isSubcategoryHeaders ? subcategoryHeader : ''

  return header
}

interface GetSearchCategoryIdParams {
  searchCategory?: string
  queryClient: QueryClient
}

export const getSearchCategoryId = ({
  searchCategory,
  queryClient
}: GetSearchCategoryIdParams) => {
  if (!searchCategory) return null

  const listingCategories = queryClient.getQueryData<Category[]>(
    listingKeys.getListingCategories()
  )

  const categoryId =
    listingCategories?.find((item) => String(item.slug) === searchCategory)
      ?.id ?? null

  return categoryId
}
